import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";
// import * as bundleCARES from "./bundleCARES.js";

export class CaresContainer extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		const script = this.mountScript();
		script.onload = () => {
			const MyApp = (window as any).MyApp;
			if (typeof MyApp !== "undefined") {
        const id = this._element.getAttribute("id");
				MyApp.renderApp(id);
			} else {
				console.error("MyApp is not defined");
			}
		};
	}

	mountScript(): HTMLScriptElement {
    console.log("Mounting CARES script");
		const script = document.createElement("script");
		script.src = "/js/bundleCARES.js";
		script.async = true;
		document.head.appendChild(script);

		return script;
	}
}

ComponentFactory.registerComponent("CaresContainer", CaresContainer);
